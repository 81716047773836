import LandingPage from "./landingPage";
import Login from "./auth/login";
import Register from "./auth/register";
import ProfileSetup from "./auth/profile-setup";
import ForgotPassword from "./auth/forgot-password";
import Otp from "./auth/otp";
import MarketplaceVendors from "./marketplaceVendor";
import VendorDetail from "./vendorDetail";
import LearnHow from "./learnHow";
import BecomeVendor from "./becomeVendor";
import BecomePartner from "./becomePartner";
import JobOpportunities from "./jobOppurtunities";
import ExploreCategory from "./explore";
import ExploreSubcategory from "./exploreSubcategory";
import SubcategoryDetail from "./subcategoryDetail";
import NftDetail from "./nftDetail";
import Cart from "./cart";
import AccountScreen from "./accountScreen";
import PrivacyPolicy from "./cms/privacyPolicy";
import Disclaimer from "./cms/disclaimer";
import About from "./cms/about";
import ResetPassword from "./auth/reset-password";
import SignupFree from "./signupFree";
import Blogs from "./blogs";
import BlogDetail from "./blogDetail";
import FAQ from "./faq";
import Contact from "./contact";
import CorporateSites from "./corporateSites";
import News from "./news";
import NewsDetail from "./newsDetail";
import OurPartners from "./ourPartners";
import PlatformNFTs from "./platformNft";
import { WebNotSupport } from "./web3NotSupport";
import FindBinMap from "./findBinMap";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FindBinMap,
  LandingPage,
  Login,
  Register,
  ProfileSetup,
  ForgotPassword,
  Otp,
  ResetPassword,
  MarketplaceVendors,
  VendorDetail,
  LearnHow,
  BecomeVendor,
  BecomePartner,
  JobOpportunities,
  ExploreCategory,
  ExploreSubcategory,
  SubcategoryDetail,
  NftDetail,
  Cart,
  AccountScreen,
  PrivacyPolicy,
  Disclaimer,
  About,
  SignupFree,
  Blogs,
  BlogDetail,
  FAQ,
  Contact,
  CorporateSites,
  News,
  NewsDetail,
  OurPartners,
  PlatformNFTs,
  WebNotSupport,
};
