import React from "react";
import * as Popover from "@radix-ui/react-popover";
import { useLocation } from "react-router-dom";
import { CollectionCenterData } from "../../services/admin/collectionCenter";
import { useGeolocationContext } from "../../App";

const ChatIconWithMap = () => {
  const location = useLocation();
  const userLocation = useGeolocationContext() as Partial<CollectionCenterData>;
  const mapUrl = `/find-bin-map`;

  const handleClick = () => {
    window.open(`${mapUrl}?lng=${userLocation?.longitude}&lat=${userLocation?.latitude}`, "_blank");
  };

  if (location.pathname === mapUrl) {
    return null;
  }

  return (
    <Popover.Root>
      <Popover.Trigger className="popoverTrigger" onClick={handleClick}>
        <img src={'/images/map.png'} alt="bin" width={24} height={24} />
        <span>Find Bin</span>
      </Popover.Trigger>
    </Popover.Root>
  );
};

export default ChatIconWithMap;
