import ReactMapGL, { GeolocateControl, Marker, NavigationControl, ScaleControl } from "react-map-gl";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";

import { CollectionCenterData, useGetCollectionCenterQuery, useGetOrganisationQuery } from "../../services/admin/collectionCenter";
import { LocationPopup } from "../../components/chatMap/locationPopup";
import { FilterButtons } from "../../components/button/filterButtons";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { MAPBOX_ACCESS_TOKEN } from "../../helpers/constants/urls";
import { useTelegram } from "../../hooks/useTelegram";

const FindBinMap = () => {
  const [searchParams] = useSearchParams();
  const { tg } = useTelegram();

  const userLocation = {
    longitude: Number(searchParams.get('lng')) || 55.481747846041145,
    latitude: Number(searchParams.get('lat')) || 25.3233379650232,
  }

  const { data } = useGetCollectionCenterQuery(undefined, { 
    selectFromResult: ({data}) => ({data: data?.data?.data})
  });

  const { data: organisation } = useGetOrganisationQuery(undefined, {
    selectFromResult: ({data}) => ({data: data?.data?.organisation})
  });

  const { filters, setFilters, filteredLocations } = useLocationFilters(data, organisation);
  const [selectedLocation, setSelectedLocation] = useState<CollectionCenterData | null>(null);

  const defaultLocation = {
    longitude: userLocation.longitude || 55.481747846041145,
    latitude: userLocation.latitude || 25.3233379650232,
    zoom: 9
  };

  useEffect(() => { 
    tg.ready();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactMapGL
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      initialViewState={defaultLocation}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      style={{width: '100vw', height: '100vh'}}
    >
      <div className="map-controls">
        <FilterButtons filters={filters} setFilters={setFilters} collectionPoints={data || []} userLocation={userLocation} />
        <NavigationControl position="bottom-right" style={{ marginBottom: '20px', marginRight: '20px' }} />
        <ScaleControl />
        <GeolocateControl position="bottom-right" style={{ marginRight: '20px' }} />
      </div>

      {filteredLocations?.map((location) => (
        location.markerIcon ? (
          <Marker 
            key={location.id}
            longitude={location.latitude} 
            latitude={location.longitude} 
            anchor="bottom"
            offset={[0, -10]}
            style={{cursor: 'pointer'}}
            onClick={e => {
              e.originalEvent.stopPropagation();
              setSelectedLocation(location);
            }}
          >
            <img 
              src={location.markerIcon} 
              alt="marker"
              style={{ width: '40px', height: '40px' }}
            />
          </Marker>
        ) : (
          <Marker 
            key={location.id}
            longitude={location.latitude} 
            latitude={location.longitude} 
            anchor="bottom"
            offset={[0, -10]}
            style={{cursor: 'pointer'}}
            color={
              location.repairWorkshop ? '#4CAF50' :
              location.tradeInStore ? '#2196F3' :
              location.recyclingContainer || (!location.repairWorkshop && !location.tradeInStore && !location.recyclingContainer) ? '#ee9a08' :
              'gray'
            }
            onClick={e => {
              e.originalEvent.stopPropagation();
              setSelectedLocation(location);
            }}
          />
        )
      ))}

      {selectedLocation && (
        <LocationPopup 
          location={selectedLocation} 
          userLocation={userLocation}
          onClose={() => setSelectedLocation(null)} 
        />
      )}

      {userLocation && userLocation.longitude && userLocation.latitude && (
        <Marker 
          longitude={userLocation.longitude} 
          latitude={userLocation.latitude} 
          anchor="bottom"
          offset={[0, -10]}
          style={{cursor: 'pointer'}}
          color="red"
        />
      )}
    </ReactMapGL>
  );
};

export default FindBinMap;
