import { toast } from "react-toastify";
import { useMap } from "react-map-gl";
import { useState } from "react";

import { CollectionCenterData } from "../../services/admin/collectionCenter";
import { useFindNearestCollectionPoint } from "../../hooks/useFindNearestCollectionPoint";

interface FilterButtonsProps {
  filters: {
    repairWorkshop: boolean;
    tradeInStore: boolean;
    recyclingContainer: boolean;
  };
  setFilters: React.Dispatch<React.SetStateAction<{
    repairWorkshop: boolean;
    tradeInStore: boolean;
    recyclingContainer: boolean;
  }>>;
  collectionPoints: CollectionCenterData[];
  userLocation: Partial<CollectionCenterData> | null;
}

export const FilterButtons: React.FC<FilterButtonsProps> = ({ filters, setFilters, collectionPoints, userLocation }) => {
  const [isNearMeActive, setIsNearMeActive] = useState(false);

  const nearestPoint = useFindNearestCollectionPoint(userLocation ?? {}, collectionPoints, 10000);

  const {current: map} = useMap();

  const onNearMeClick = () => {
    if (!nearestPoint) {
      toast.error("No collection point found near you!");
      return;
    }

    if (isNearMeActive) {
      map?.flyTo({center: [userLocation?.longitude || 0, userLocation?.latitude || 0], zoom: 9});
      setIsNearMeActive(false);
    } else {
      map?.flyTo({center: [nearestPoint?.latitude, nearestPoint?.longitude], zoom: 14});
      setIsNearMeActive(true);
    }
  };
  
  return (
    <div className="map-controls__buttons">
      <button
        onClick={() => setFilters(prev => ({ ...prev, repairWorkshop: !prev.repairWorkshop }))}
        className={`filter-button filter-button--repair ${filters.repairWorkshop ? 'active' : ''}`}
      >
        Repair
      </button>

      <button
        onClick={() => setFilters(prev => ({ ...prev, tradeInStore: !prev.tradeInStore }))}
        className={`filter-button filter-button--trade-in ${filters.tradeInStore ? 'active' : ''}`}
      >
        Trade-in
      </button>

      <button
        onClick={() => setFilters(prev => ({ ...prev, recyclingContainer: !prev.recyclingContainer }))}
        className={`filter-button filter-button--recycling ${filters.recyclingContainer ? 'active' : ''}`}
      >
        Recycling
      </button>
      <button 
        onClick={onNearMeClick}
        className={`filter-button filter-button--near-me ${isNearMeActive ? 'active' : ''}`}
      >
        Near Me
      </button>
    </div>
  );
}; 