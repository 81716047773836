import { useState, useMemo } from 'react';

import { CollectionCenterData } from '../services/admin/collectionCenter';
import { User } from '../types/User';

interface FilterState {
  repairWorkshop: boolean;
  tradeInStore: boolean;
  recyclingContainer: boolean;
}

export const useLocationFilters = (locations: CollectionCenterData[] | undefined, organisation: User[] | undefined) => {

  const [filters, setFilters] = useState<FilterState>({
    repairWorkshop: false,
    tradeInStore: false,
    recyclingContainer: false
  });

  const filteredLocations = useMemo(() => {
    if (!locations || !organisation) return [];
    
    return locations.map(location => {
      const locationOrg = organisation.find?.(org => org._id === location.b2bUnitId) || null;
      return {
        ...location,
        markerIcon: locationOrg?.mapIcon || null
      };
    }).filter(location => {
      if (!filters.repairWorkshop && !filters.tradeInStore && !filters.recyclingContainer) {
        return true;
      }
      
      const hasNoServices = !location.repairWorkshop && !location.tradeInStore && !location.recyclingContainer;
      
      return (
        (filters.repairWorkshop && location.repairWorkshop) ||
        (filters.tradeInStore && location.tradeInStore) ||
        (filters.recyclingContainer && (location.recyclingContainer || hasNoServices))
      );
    });
  }, [locations, filters, organisation]);

  return { filters, setFilters, filteredLocations };
};