const tg = window.Telegram.WebApp;

export function useTelegram() {
  tg.expand();

  return {
    tg,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
    onClose: () => tg.close(),
    onToggleButton: () => tg.MainButton.toggle(),
  };
}