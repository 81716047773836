import { Popup } from 'react-map-gl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';

import { CollectionCenterData } from '../../services/admin/collectionCenter';

interface LocationPopupProps {
  location: CollectionCenterData;
  onClose: () => void;
  userLocation: {
    longitude: number;
    latitude: number;
  };
}

export const LocationPopup: React.FC<LocationPopupProps> = ({ userLocation, location, onClose }) => {

  const handleDirectionsClick = () => {
    const url = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.latitude},${userLocation.longitude}&destination=${location.longitude},${location.latitude}`;
    window.open(url, '_blank');
  };

  return (
    <Popup
      longitude={location.latitude}
      latitude={location.longitude}
      onClose={onClose}
      anchor="bottom"
      offset={[15, -45] as [number, number]}
      closeButton={false}
      closeOnClick={false}
      className="custom-popup"
    >
      <div className="location-popup">
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        <div className="location-popup__address">
          {location.address}
        </div>

        {location.acceptableEwaste && (
          <div className="location-popup__ewaste">
            <div className="location-popup__ewaste-title">
              Acceptable e-waste:
            </div>
            <div className="location-popup__ewaste-content">
              {location.acceptableEwaste}
            </div>
          </div>
        )}

        <div className="location-popup__tags">
          {location.repairWorkshop && (
            <span className="location-tag location-tag--repair">
              Repair
            </span>
          )}
          {location.tradeInStore && (
            <span className="location-tag location-tag--trade-in">
              Trade-in
            </span>
          )}
          {(!location.repairWorkshop && !location.tradeInStore && !location.recyclingContainer) || location.recyclingContainer ? (
            <span className="location-tag location-tag--recycling">
              Recycling
            </span>
          ) : null}
          <button className="location-popup__direction" onClick={handleDirectionsClick}>
            <DirectionsIcon fontSize="small" style={{ marginRight: '2px' }} />
            Direction
          </button>
        </div>
      </div>
    </Popup>
  );
}; 