import { CollectionCenterData } from "../services/admin/collectionCenter";

export function useFindNearestCollectionPoint(
  userLocation: Partial<CollectionCenterData>,
  centers: CollectionCenterData[],
  maxRadius: number // in meters
): CollectionCenterData | null {
  if (!userLocation.latitude || !userLocation.longitude) return null;
  
  let nearestCenter: CollectionCenterData | null = null;
  let minDistance = Infinity;

  centers.forEach(center => {
    const distance = calculateDistance(
      userLocation.longitude!,
      userLocation.latitude!,
      center.latitude,
      center.longitude,
    );

    if (distance <= maxRadius && distance < minDistance) {
      minDistance = distance;
      nearestCenter = center;
    }
  });
  return nearestCenter;
}

function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371000; // Earth's radius in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
}