import Routing from "./Routes";
import "./App.scss";
import { useAppDispatch } from "./hooks/store";
import { createContext, useContext, useEffect, useState } from "react";
import { useLazyGetCmsQuery } from "./services/cms";
import { resetCms } from "./reducers/generalSlice";
// import { WebNotSupport } from "./pages/web3NotSupport";
import ChatIconWithMap from "./components/chatMap/chatIconWithMap";
import { CollectionCenterData } from "./services/admin/collectionCenter";
import { useGeolocation } from "./hooks/useGeolocation";

const GeolocationContext = createContext<Partial<CollectionCenterData> | null>(null);

export const GeolocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userLocation = useGeolocation();
  return (
    <GeolocationContext.Provider value={userLocation}>
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocationContext = () => useContext(GeolocationContext);

function App() {
  const dispatch = useAppDispatch();

  const [getCmsMethod] = useLazyGetCmsQuery();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (window && window.ethereum) {
      if (typeof window.ethereum !== "undefined") {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, []);

  const getCmsData = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getCmsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getUserDetails = async () => {
  //   const token = await getFromStorage(STORAGE_KEYS.token);
  //   console.log(token, "tokentoken");

  //   if (token) {
  //     try {
  //       const result = await getUser({}).unwrap();
  //       if (result?.statusCode === 200 && token) {
  //         dispatch(
  //           setCredentials({
  //             user: result?.data,
  //             token: token,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const token = getFromStorage(STORAGE_KEYS.token);
  //   console.log(token, "tokentoken>>>>>>");
  //   const userData = getFromStorage(STORAGE_KEYS.credentials);
  //   if (token) {
  //     dispatch(
  //       setCredentials({
  //         token: `${token}`,
  //         user: JSON.parse(`${userData}`),
  //       })
  //     );
  //     getUserDetails();
  //   }
  // }, []);

  // return <>{isValid ? <Routing /> : <WebNotSupport />}</>;
  return (
    <GeolocationProvider>
      <Routing />
      <ChatIconWithMap />
    </GeolocationProvider>
  );
}

export default App;
