import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/store";
import {
  API_URL,
  STORAGE_KEYS,
  removeFromStorage,
} from "../helpers";
import { metaMaskAddress, resetAuth } from "../reducers/authSlice";
import { generateSEKAndHash } from "./crypto";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  // prepareHeaders: (headers, { getState }) => {
  //   const { token } = (getState() as RootState).auth;

  //   if (token) {
  //     headers.set("authorization", `Bearer ${token}`);
  //   }
  //   return headers;
  // },
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    const { tempToken } = (getState() as RootState).auth;

    const encryptData = generateSEKAndHash(token || tempToken || null);

    if (encryptData) {
      headers.set("hash", encryptData?.hash);
      headers.set("sek", encryptData?.sek);
    }
    headers.set("deviceType", "web");
    // headers.set("wtoken", token ? `${false}` : `${true}`);

    // if (token) {
    //   headers.set("authorization", `Bearer ${token}`);
    // } else if (tempToken) {
    //   headers.set("authorization", `Bearer ${tempToken}`);
    // }
    // headers.set("Hash", `${encryptedData?.hash}`);
    // headers.set("Sek", ` ${encryptedData?.sek}`);
    return headers;
  },
});

const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // if (args.body) {
  //   const encryptedData = generateEncryptedKeyBody(args.body);
  //   if (encryptedData) {
  //     args.body = encryptedData;
  //   } else {
  //     return {
  //       error: {
  //         status: 400,
  //         data: { detail: "Failed to encrypt request body" },
  //       },
  //     };
  //   }
  // }
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 400) {
    const errors = Object.values(result?.error?.data || {});
    console.log({ errors });
    if (errors?.length > 1 && errors[1] === 400) {
      return result;
    }

    if (errors?.length) {
      const error = errors[0] as any;
      if (error?.length) {
        console.log(error[0]);
      }
    }
  }
  if (result.error?.status === 401) {
    removeFromStorage(STORAGE_KEYS.WalletAddress);
    // errorToast("Your account is deactivated please contact to admin");
    api.dispatch(resetAuth());
    api.dispatch(
      metaMaskAddress({
        walletAddress: null,
      })
    );
    if (window) {
      window.location.replace("/");
    }
  }
  if (
    result.error &&
    result.error.status === 401 &&
    (api.getState() as RootState).auth.user
  ) {
    api.dispatch(resetAuth());
    api.dispatch(
      metaMaskAddress({
        walletAddress: null,
      })
    );
  }

  return result;
};

const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});

export default emptySplitApi;
