/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { useLazyGetMyWalletQuery } from "../../../services/main/setting";
import { MyWallet } from "../../../types/General";
import Web3 from "web3";

const Wallet = () => {
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);
  const [myWallet] = useLazyGetMyWalletQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState<MyWallet>();
  const [walletBalance, setWalletBalance] = useState<any>();

  const getMyWallet = async () => {
    setIsLoading(true);
    try {
      const result = await myWallet({}).unwrap();
      if (result?.statusCode === 200) {
        setWalletData(result?.data || []);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getWalletBalance = async () => {
    const web3 = new Web3(window.ethereum);
    if (walletAddress) {
      const balance = await web3.eth.getBalance(walletAddress);
      setWalletBalance(balance);
    }
  };

  useEffect(() => {
    getMyWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWalletBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return (
    <>
      <Loader isLoad={isLoading} />
      {<h4>Balance:</h4>}
      {/* <p>Balance:</p> */}
      <ul className="wallet_listing hd_3">
        {/* {walletAddress ? (
          <li>
            <p>MATIC Available Balance</p>
            <div className="d_flex">
              <h4>{walletBalance?.toLocaleString()}</h4>
            </div>
          </li>
        ) : undefined} */}
        {/* <li>
          <p>Carbon Credits</p>
          <div className="d_flex">
            <h4>{walletData?.carbonCredits.toLocaleString()}</h4>
          </div>
        </li>
        <li>
          <p>I2E Available Balance</p>
          <div className="d_flex">
            <h4>{walletData?.I2E_Balance.toLocaleString()}</h4>
          </div>
        </li> */}
        <li>
          <div className="inner">
            <div className="d_flex">
              <p>i2e Points</p>
              <p>{walletData?.I2E_Balance.toLocaleString()}</p>
            </div>
            <div className="d_flex">
              <p>Carbon Points</p>
              <p>{walletData?.carbonCredits.toLocaleString()}</p>
            </div>
            {/*<div className="d_flex">
              <p>Passive Asset</p>
              <p>{walletData?.passiveNftAllocation?.toLocaleString()}</p>
            </div>
            <div className="d_flex">
              <p>Minted Asset</p>
              <p>{walletData?.mintedNftAllocation.toLocaleString()}</p>
            </div>
             <div className="d_flex">
              <p>Scrap Mobile</p>
              <p>645</p>
            </div>
            <div className="d_flex">
              <p>Scrap Desktop</p>
              <p>145</p>
            </div>
            <div className="d_flex">
              <p>Scrap Bin</p>
              <p>345</p>
            </div>
            <div className="d_flex">
              <p>Passive Asset</p>
              <p>445</p>
            </div>
            <div className="d_flex">
              <p>Minted Asset</p>
              <p>245</p>
            </div>
            <div className="d_flex">
              <p>Scrap Mobile</p>
              <p>645</p>
            </div> */}
          </div>
        </li>
      </ul>
    </>
  );
};

export default Wallet;
