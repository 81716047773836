/* eslint-disable jsx-a11y/img-redundant-alt */
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { CommonBody, NftResponse } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
import { useConvertToPassiveMutation } from "../../../services/main/setting";
import {
  Loader,
  STORAGE_KEYS,
  setToStorage,
  successToast,
} from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { NFTS_STATUS } from "../../../utils/enums";

const UnapprovedNft_Detail = ({ nfts }: { nfts: NftResponse | undefined }) => {
  const user = useAuth();
  const navigate = useNavigate();

  const [setAsPassiveMethod, { isLoading }] = useConvertToPassiveMutation();

  const handlePassiveClick = async () => {
    let data = {
      nftType: 1,
    };
    try {
      const body = generateEncryptedKeyBody(data) as CommonBody;
      const result = await setAsPassiveMethod({
        body,
        nftId: nfts?._id || "",
      }).unwrap();
      if (result?.statusCode === 200) {
        await setToStorage(STORAGE_KEYS.activeTab, "1");
        successToast(result?.message || "");
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <p className="links">{`Owned by: ${nfts?.ownerName || "Admin"}`}</p>
      {/* <ul className="favs_views">
        <li>
          <RemoveRedEyeIcon /> 419 views
        </li>
        <li>
          <FavoriteIcon /> 9 favorites
        </li>
        <li>
          <img src="/images/circleStar_icon.svg" alt="Icon" /> 200{" "}
        </li>
      </ul> */}
      <div className="p_group">
        <p className="f_med">{`Applied On : ${moment(nfts?.createdAt).format(
          "D - MMM - YYYY"
        )}`}</p>
        <p className="f_med">
          Status :{" "}
          <span className="c_warning">
            {/* @ts-ignore */}
            {NFTS_STATUS[nfts?.status ? Number(nfts?.status) : 3]}
          </span>
        </p>
        <p className="f_med">
          <b>Sponsor Name :{" "}</b>
          <span className="c_warning">
            <div className="step_box_single">
              <figure>
                <img src="/images/sponsor/gadgets_reborn_logo.jpg" alt="Image" style={{ marginTop: 20 }} />
              </figure>
              <p style={{ marginTop: 15 }}>
                <b>
                  Congratulations! Your deposit has been cataloged for this local recycling challenge! To increase your chances of being one of three winners, continue to make deposits to add to your overall total weight of recycled items.
                </b>
              </p>
            </div>
            {/* @ts-ignore
            {nfts?.sponsor} */}
          </span>
        </p>
      </div>
      {/* {nfts?.status && Number(nfts?.status) === 2 ? undefined : (
        <div className="btn_flex">
          <button onClick={handlePassiveClick} className="btn btn_primary">
            Use as Passive
          </button>
        </div>
      )} */}
    </>
  );
};

export default UnapprovedNft_Detail;
