/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { useLazyGetBannerQuery } from '../../../services/main/home';
import { Loader } from '../../../helpers';
import { banner } from '../../../types/General';

const HomeHero = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const [banner, setBanner] = useState<banner[]>([]);
  const [swiperReady, setSwiperReady] = useState(false);

  const [getBannerMethod, { isLoading }] = useLazyGetBannerQuery();

  const getBannerDetails = async () => {
    try {
      const result = await getBannerMethod({}).unwrap();
      if (result?.statusCode === 200) {
        setBanner(result?.data || []);
        setSwiperReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBannerDetails();
  }, []);

  return (
    <section className="hero_home_sc uhb_spc">
      <Loader isLoad={isLoading} />
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={3000}
        pagination={{ clickable: true }}
        loop={true}
        className="hero_swiper"
      >
        {banner?.length && swiperReady
          ? banner?.map((item) => (
            <SwiperSlide key={item?._id}>
              <div className="conta_iner">
                <div className="gap_p aic">
                  <div className="left_s">
                    <h1>{item?.title || ''}</h1>
                    <div className="btn_flex">
                      <a
                        target="_blank"
                        href="https://wa.me/971553386994?text=start"
                        className="btn btn_lg btn_primary_WA"
                      >
                        {item?.button1}
                      </a>
                      <a
                        target="_blank"
                        href="https://t.me/bantgo_bot"
                        className="btn btn_lg btn_primary_TG"
                      >
                        {item?.button2}
                      </a>
                      {/* {user ? undefined : (
                        
                      )} */}
                    </div>
                  </div>
                  <div className="right_s">
                    <figure>
                      <img
                        src={
                          item?.bannerImage ||
                          '/images/banner_attachment.png'
                        }
                        alt="Image"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
          : undefined}
      </Swiper>
    </section>
  );
};

export default HomeHero;
